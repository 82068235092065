import { Swap } from "@zinuswap/uikit";
import { PageMeta } from "components/Layout/Page";
import { EXCHANGE_HELP_URLS } from "config/constants";

const Page: React.FC<
  React.PropsWithChildren<{
    removePadding?: boolean;
    hideFooterOnDesktop?: boolean;
    noMinHeight?: boolean;
    helpUrl?: string;
  }>
> = ({
  children,
  removePadding = false,
  hideFooterOnDesktop = false,
  noMinHeight = false,
  helpUrl = EXCHANGE_HELP_URLS,
  ...props
}) => {
  return (
    <>
      <PageMeta />
      <Swap.Page
        removePadding={removePadding}
        noMinHeight={noMinHeight}
        hideFooterOnDesktop={hideFooterOnDesktop}
        helpUrl={helpUrl}
        {...props}
      >
        {children}
      </Swap.Page>
    </>
  );
};

export default Page;
