import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 13 13" {...props}>
      <path d="M5 0.710526L10 3.60526V9.39474L5 12.2895L0 9.39474V3.60526L5 0.710526ZM5 1.92684L1.05263 4.2121V8.78789L5 11.0732L8.94737 8.78789V4.2121L5 1.92684ZM5 8.60526C4.44165 8.60526 3.90617 8.38346 3.51135 7.98864C3.11654 7.59383 2.89474 7.05835 2.89474 6.5C2.89474 5.94165 3.11654 5.40617 3.51135 5.01135C3.90617 4.61654 4.44165 4.39474 5 4.39474C5.55835 4.39474 6.09383 4.61654 6.48865 5.01135C6.88346 5.40617 7.10526 5.94165 7.10526 6.5C7.10526 7.05835 6.88346 7.59383 6.48865 7.98864C6.09383 8.38346 5.55835 8.60526 5 8.60526ZM5 7.55263C5.27918 7.55263 5.54692 7.44173 5.74432 7.24432C5.94173 7.04691 6.05263 6.77917 6.05263 6.5C6.05263 6.22082 5.94173 5.95308 5.74432 5.75568C5.54692 5.55827 5.27918 5.44737 5 5.44737C4.72082 5.44737 4.45308 5.55827 4.25568 5.75568C4.05827 5.95308 3.94737 6.22082 3.94737 6.5C3.94737 6.77917 4.05827 7.04691 4.25568 7.24432C4.45308 7.44173 4.72082 7.55263 5 7.55263Z" />
    </Svg>
  );
};

export default Icon;
