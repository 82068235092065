import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 11 12" {...props}>
      <g clip-path="url(#clip0_62_9102)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.3125 6.28711C10.3125 7.56346 9.80547 8.78754 8.90295 9.69006C8.00043 10.5926 6.77635 11.0996 5.5 11.0996C4.22365 11.0996 2.99957 10.5926 2.09705 9.69006C1.19453 8.78754 0.6875 7.56346 0.6875 6.28711C0.6875 5.01076 1.19453 3.78668 2.09705 2.88416C2.99957 1.98164 4.22365 1.47461 5.5 1.47461C6.77635 1.47461 8.00043 1.98164 8.90295 2.88416C9.80547 3.78668 10.3125 5.01076 10.3125 6.28711ZM6.1875 4.22461C6.1875 4.40695 6.11507 4.58181 5.98614 4.71075C5.8572 4.83968 5.68234 4.91211 5.5 4.91211C5.31766 4.91211 5.1428 4.83968 5.01386 4.71075C4.88493 4.58181 4.8125 4.40695 4.8125 4.22461C4.8125 4.04227 4.88493 3.8674 5.01386 3.73847C5.1428 3.60954 5.31766 3.53711 5.5 3.53711C5.68234 3.53711 5.8572 3.60954 5.98614 3.73847C6.11507 3.8674 6.1875 4.04227 6.1875 4.22461ZM4.64062 6.28711C4.50387 6.28711 4.37272 6.34143 4.27602 6.43813C4.17932 6.53483 4.125 6.66598 4.125 6.80273C4.125 6.93949 4.17932 7.07064 4.27602 7.16734C4.37272 7.26403 4.50387 7.31836 4.64062 7.31836H5.15625V8.52148C5.15625 8.65824 5.21057 8.78939 5.30727 8.88609C5.40397 8.98278 5.53512 9.03711 5.67188 9.03711C5.80863 9.03711 5.93978 8.98278 6.03648 8.88609C6.13317 8.78939 6.1875 8.65824 6.1875 8.52148V6.80273C6.1875 6.66598 6.13317 6.53483 6.03648 6.43813C5.93978 6.34143 5.80863 6.28711 5.67188 6.28711H4.64062Z"
          fill="url(#paint0_linear_62_9102)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_62_9102"
          x1="5.50175"
          y1="-0.166558"
          x2="5.50175"
          y2="16.2873"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <clipPath id="clip0_62_9102">
          <rect width="11" height="11" fill="white" transform="translate(0 0.787109)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
