import styled from "styled-components";
import { Card } from "@zinuswap/uikit";

export const BodyWrapper = styled(Card)`
  border-radius: 24px;
  max-width: 436px;
  width: 100%;
  z-index: 1;
  border-top: 1px solid ${({ theme }) => theme.colors.stroke};
  background: #141414;
`;

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({
  children,
}: {
  children: React.ReactNode;
}) {
  return <BodyWrapper>{children}</BodyWrapper>;
}
