import styled from "styled-components";
import {
  Text,
  Flex,
  Heading,
  IconButton,
  ArrowBackIcon,
  NotificationDot,
  QuestionHelper,
} from "@zinuswap/uikit";
import { useExpertModeManager } from "state/user/hooks";
import GlobalSettings from "components/Menu/GlobalSettings";
import Link from "next/link";
import Transactions from "./Transactions";
import { SettingsMode } from "../Menu/GlobalSettings/types";

interface Props {
  title: string;
  subtitle?: string;
  helper?: string;
  backTo?: string | (() => void);
  noConfig?: boolean;
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0px;
  width: 100%;
`;

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  helper,
  backTo,
  noConfig = false,
}) => {
  const [expertMode] = useExpertModeManager();

  return (
    <AppHeaderContainer>
      <Flex alignItems="center" width="100%" style={{ gap: "16px" }}>
        {backTo &&
          (typeof backTo === "string" ? (
            <Link passHref href={backTo}>
              <IconButton as="a" scale="sm">
                <ArrowBackIcon width="32px" />
              </IconButton>
            </Link>
          ) : (
            <IconButton scale="sm" variant="text" onClick={backTo}>
              <ArrowBackIcon width="32px" />
            </IconButton>
          ))}
        <Flex flexDirection="column" width="100%">
          <Flex
            mb="8px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex>
              <Heading as="h2">{title}</Heading>
              {helper && (
                <QuestionHelper
                  text={helper}
                  ml="4px"
                  placement="top-start"
                />
              )}
            </Flex>
            {!noConfig && (
              <Flex
                alignItems="center"
                flexDirection={backTo ? "row-reverse" : "row"}
              >
                <NotificationDot show={expertMode}>
                  <GlobalSettings
                    mode={SettingsMode.SWAP_LIQUIDITY}
                  />
                </NotificationDot>
                <Transactions />
              </Flex>
            )}
          </Flex>
          <Flex alignItems="center">
            <Text color="textSubtle" fontSize="14px">
              {subtitle}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </AppHeaderContainer>
  );
};

export default AppHeader;
