import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="33" height="33" rx="16.5" fill="#1E1E1E" />
      <rect x="0.5" y="1" width="33" height="33" rx="16.5" stroke="url(#paint0_linear_417_3709)" />
      <path
        d="M11 24.5L7 20.5L8.4 19.05L10 20.65V13.5C10 12.4 10.3917 11.4583 11.175 10.675C11.9583 9.89167 12.9 9.5 14 9.5C15.1 9.5 16.0417 9.89167 16.825 10.675C17.6083 11.4583 18 12.4 18 13.5V20.5C18 21.05 18.196 21.521 18.588 21.913C18.98 22.305 19.4507 22.5007 20 22.5C20.55 22.5 21.021 22.3043 21.413 21.913C21.805 21.5217 22.0007 21.0507 22 20.5V13.35L20.4 14.95L19 13.5L23 9.5L27 13.5L25.6 14.95L24 13.35V20.5C24 21.6 23.6083 22.5417 22.825 23.325C22.0417 24.1083 21.1 24.5 20 24.5C18.9 24.5 17.9583 24.1083 17.175 23.325C16.3917 22.5417 16 21.6 16 20.5V13.5C16 12.95 15.8043 12.4793 15.413 12.088C15.0217 11.6967 14.5507 11.5007 14 11.5C13.45 11.5 12.9793 11.696 12.588 12.088C12.1967 12.48 12.0007 12.9507 12 13.5V20.65L13.6 19.05L15 20.5L11 24.5Z"
        fill="url(#paint1_linear_417_3709)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_417_3709"
          x1="17.0062"
          y1="-5.29737"
          x2="17.0062"
          y2="52.8253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_417_3709"
          x1="17.0036"
          y1="6.94234"
          x2="17.0036"
          y2="32.5847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A088F2" />
          <stop offset="1" stop-color="#524FFB" />
          <stop offset="1.0001" stop-color="#524FFB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
