import {
  Flex,
  IconButton,
  CogIcon,
  useModal,
  Text,
} from "@zinuswap/uikit";
import styled from "styled-components";
import SettingsModal from "./SettingsModal";

type Props = {
  color?: string;
  mr?: string;
  mode?: string;
};

const SettingsButton = styled(IconButton)`
  background: #121212;
  border-radius: 10px;
  padding: 10px 15px;
  gap: 5px;
  width: 100%;
`;

const GlobalSettings = ({ color, mr = "8px", mode }: Props) => {
  const [onPresentSettingsModal] = useModal(
    <SettingsModal mode={mode} />
  );

  return (
    <SettingsButton
      onClick={onPresentSettingsModal}
      variant="text"
      scale="md"
      id={`open-settings-dialog-button-${mode}`}
    >
      <CogIcon height={24} width={24} color={color || "textSubtle"} />
      <Text small>Settings</Text>
    </SettingsButton>
  );
};

export default GlobalSettings;
